
export const environment = {
production: true, 
imageKitUrl: "https://ik.imagekit.io/wgucm6iv5/development", 
appVersion: "v717demo1", 
firebaseStorageUrl: "https://firebasestorage.googleapis.com/v0/b/flipteria-23079.appspot.com/o", 
USERDATA_KEY: "authf649fc9a5f55", 
isMockEnabled: "true", 
apiUrl: "api", 
apiUrlNew: "https://api.dev.flipteria.lk/api/v2", 
customerPortlUrl: "https://dev.flipteria.lk", 
firebaseConfig: { apiKey : "AIzaSyC9BN-YlQ1PExGg4PfEHvPpXmAyHKDE0BU", 
authDomain : "flipteria-23079.firebaseapp.com", 
projectId : "flipteria-23079", 
storageBucket : "flipteria-23079.appspot.com", 
messagingSenderId : "609003747800", 
appId : "1:609003747800:web:3b1bc230617ee5a3b3daad", 
measurementId : "G-HLE5MH4SB7", 
},bunnyConfig: { apiUrl : "https://sg.storage.bunnycdn.com", 
storageZoneName : "flipteriasecond", 
accessKey : "5d6b1014-6123-450b-83ee91d2d051-3c9d-4d89", 
path : "/development/product_images", 
},azureBlobUrl: "https://flipteria.blob.core.windows.net", 
test: "dev", 
sellerPortalUrl: "https://sellercenter.dev.flipteria.lk", 
sellerImpersonateUrl: "https://sellercenter-imp.dev.flipteria.lk/auth/session/", 
applicationServerPublicKey: "BFsZ8GeWzpZTHboaxFIHExblP8wLzADhbfNw8cIMVpLlK-qoXafaFDJNSYzLk53VtKQYf9UzAF4U1wCoQZQWws4", 
};