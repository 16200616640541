import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { BreakpointObserver } from '@angular/cdk/layout';
import { Location } from '@angular/common';
import { SplashScreenService } from './_metronic/partials/layout/splash-screen/splash-screen.service';
import { Subscription } from 'rxjs';
import { TranslationService } from './modules/i18n/translation.service';
import { locale as chLang } from './modules/i18n/vocabs/ch';
import { locale as deLang } from './modules/i18n/vocabs/de';
// language list
import { locale as enLang } from './modules/i18n/vocabs/en';
import { locale as esLang } from './modules/i18n/vocabs/es';
import { locale as frLang } from './modules/i18n/vocabs/fr';
import { locale as jpLang } from './modules/i18n/vocabs/jp';
import { Meta } from '@angular/platform-browser';
import { AuthService } from './modules/auth';
import { NotificationMiddlewareService } from './_metronic/core/services/notification-middleware.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'body[root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

  constructor(
    private translationService: TranslationService,
    private splashScreenService: SplashScreenService,
    private router: Router,
    private bpo: BreakpointObserver,
    private location: Location,
    private metaService: Meta,
    private auth: AuthService,
    private notificationMiddleware: NotificationMiddlewareService
  ) {
    // register translations
    this.translationService.loadTranslations(
      enLang,
      chLang,
      esLang,
      jpLang,
      deLang,
      frLang
    );
  }

  ngOnInit() {
    this.notificationMiddleware.init();
    const routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // clear filtration paginations and others
        // hide splash screen
        this.splashScreenService.hide();

        // scroll to top on every route change
        window.scrollTo(0, 0);

        // to display back the body content
        setTimeout(() => {
          document.body.classList.add('page-loaded');
        }, 500);
      }
    });

    // listen to mobile more function
    const moreFuncSubscription = this.auth.isMoreFunctionSubject.subscribe(
      (val) => {
        const viewport = val
          ? 'width=1024'
          : 'width=device-width, initial-scale=1';
        this.metaService.updateTag({ name: 'viewport', content: viewport });
      }
    );

    this.unsubscribe.push(routerSubscription);
    this.unsubscribe.push(moreFuncSubscription);
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
