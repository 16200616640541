import { ResetPasswordModel } from './../../_models/reset-password.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserModel } from '../../_models/user.model';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthHTTPService {
  constructor(private http: HttpClient) {}

  // public methods
  login(email: string, password: string): Observable<any> {
    return this.http.post<UserModel>(
      `${environment.apiUrlNew}/account/sellerLogin`,
      { email, password }
    );
  }

  logoutServer(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrlNew}/account/Logout`);
  }

  // CREATE =>  POST: add a new user to the server
  createUser(user: UserModel): Observable<UserModel> {
    return this.http.post<UserModel>(
      `${environment.apiUrlNew}/account/registerSeller`,
      user
    );
  }

  // Your server should check email => If email exists send link to the user and return true | If email doesn't exist return false
  forgotPassword(email: string, clientURI: string): Observable<boolean> {
    return this.http.post<boolean>(
      `${environment.apiUrlNew}/account/ForgotPasswordSeller`,
      {
        email,
        clientURI,
      }
    );
  }

  resetPassword(resetPassword: ResetPasswordModel): Observable<boolean> {
    return this.http.post<boolean>(
      `${environment.apiUrlNew}/account/ResetPasswordSeller`,
      resetPassword
    );
  }

  verifyPhoneNumber(phoneNumber: string): Observable<boolean> {
    return this.http.post<boolean>(
      `${environment.apiUrlNew}/account/VerifyPhoneNumber`,
      {
        PhoneNumber: phoneNumber,
      }
    );
  }

  checkEmailExists(email: string): Observable<boolean> {
    return this.http.get<boolean>(
      `${environment.apiUrlNew}/account/emailexists?email=${email}&portal=seller`
    );
  }

  checkShopNameExists(shopName: string): Observable<boolean> {
    return this.http.get<boolean>(
      `${environment.apiUrlNew}/account/shopNameExists?shopName=${shopName}`
    );
  }

  isAuthorized(token): Observable<UserModel> {
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    return this.http.get<UserModel>(
      `${environment.apiUrlNew}/account/isAuthorized`,
      {
        headers: httpHeaders,
      }
    );
  }
}
