import { AuthService } from 'src/app/modules/auth';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DesktopDeviceGuard implements CanActivate {
  constructor(private router: Router, private auth: AuthService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const width = window.innerWidth >= 850;
    //const isAdmin:boolean = localStorage.getItem(`${environment.appVersion}-${environment.USERDATA_KEY}`) !== null && JSON.parse(localStorage.getItem(`${environment.appVersion}-${environment.USERDATA_KEY}`))?.isAdmin;
    const isAdmin: boolean = this.auth.isAdminSubject.value;
    this.auth.setPrevScreenStateValue('desktop');

    if (!width && !isAdmin) {
      return this.router.navigateByUrl('/m');
    }

    return true;
  }
}
