import { tap } from 'rxjs/operators';
import { ResetPasswordModel } from './../_models/reset-password.model';
import { Injectable, OnDestroy } from '@angular/core';
import {
  Observable,
  BehaviorSubject,
  of,
  Subscription,
  throwError,
} from 'rxjs';
import { map, catchError, switchMap, finalize } from 'rxjs/operators';
import { UserModel } from '../_models/user.model';
import { AuthModel } from '../_models/auth.model';
import { AuthHTTPService } from './auth-http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  // private fields
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;

  // public fields
  currentUser$: Observable<UserModel>;
  isLoading$: Observable<boolean>;
  currentUserSubject: BehaviorSubject<UserModel>;
  isLoadingSubject: BehaviorSubject<boolean>;
  isAdminSubject: BehaviorSubject<boolean>;
  isMoreFunctionSubject: BehaviorSubject<boolean>;
  prevScreenStateSubject: BehaviorSubject<string>;

  get currentUserValue(): UserModel {
    return this.currentUserSubject.value;
  }

  set currentUserValue(user: UserModel) {
    this.currentUserSubject.next(user);
  }

  get isAdmin(): boolean {
    return this.isAdminSubject.value;
  }

  constructor(
    private authHttpService: AuthHTTPService,
    private router: Router
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.currentUserSubject = new BehaviorSubject<UserModel>(undefined);
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
    const subscr = this.getUserByToken().subscribe();
    this.unsubscribe.push(subscr);
    this.isAdminSubject = new BehaviorSubject<boolean>(false);
    this.isMoreFunctionSubject = new BehaviorSubject<boolean>(false);
    this.prevScreenStateSubject = new BehaviorSubject<string>(undefined);
  }

  login(email: string, password: string) {
    return this.authHttpService.login(email, password).pipe(
      map((user) => {
        var decoded: any = jwt_decode(user.accessToken);
        if (decoded?.role == 'Admin' || Array.isArray(decoded?.role)) {
          decoded?.role.find((f) => f === 'Admin')
            ? this.isAdminSubject.next(true)
            : this.isAdminSubject.next(false);
        } else {
          this.isAdminSubject.next(false);
        }

        // Set User data for Sellers
        user.id = decoded.nameid;
        user.verified = decoded.verified ? true : false;

        this.setAuthFromLocalStorage(user);
        this.currentUserSubject.next(user);
        return user;
      })
    );
  }

  logout() {
    this.authHttpService.logoutServer().subscribe();
    localStorage.removeItem(this.authLocalStorageToken);
    // this.router.navigate(['/auth/login'], {
    //   queryParams: {},
    // });
  }

  logoutObs(): Observable<any> {
    return this.authHttpService.logoutServer().pipe(
      tap((res) => {
        localStorage.removeItem(this.authLocalStorageToken);
      })
    );
  }

  getUserByToken(): any {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.accessToken) {
      return of(undefined);
    }
    this.isLoadingSubject.next(true);
    return this.authHttpService.isAuthorized(auth.accessToken).pipe(
      map((user: any) => {
        if (user) {
          let localStorageUser = this.getAuthFromLocalStorage();
          this.currentUserSubject = new BehaviorSubject<UserModel>(
            localStorageUser
          );
          var decoded: any = jwt_decode(auth.accessToken);
          if (decoded?.role == 'Admin' || Array.isArray(decoded?.role)) {
            decoded?.role.find((f) => f === 'Admin')
              ? this.isAdminSubject.next(true)
              : this.isAdminSubject.next(false);
          } else {
            this.isAdminSubject.next(false);
          }
        } else {
          this.logout();
        }
        return user;
      }),
      catchError((err) => {
        this.logout();
        console.error('err', err);
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  // need create new user then login
  registration(user: UserModel): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.createUser(user).pipe(
      map(() => {
        this.isLoadingSubject.next(false);
      }),
      switchMap(() => this.login(user.email, user.password)),
      catchError((err) => {
        return throwError(err);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  forgotPassword(email: string, clientUrl: string): Observable<boolean> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .forgotPassword(email, clientUrl)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  resetPassword(resetPassword: ResetPasswordModel): Observable<boolean> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .resetPassword(resetPassword)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  veryfyPhoneNumber(phoneNumber: string): Observable<boolean> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .verifyPhoneNumber(phoneNumber)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  isEmailUnique(email: string): Observable<boolean> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .checkEmailExists(email)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  isShopNameUnique(shopName: string): Observable<boolean> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .checkShopNameExists(shopName)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  // private methods
  private setAuthFromLocalStorage(auth: AuthModel): boolean {
    // store auth accessToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
    if (auth && auth.accessToken) {
      localStorage.setItem(this.authLocalStorageToken, JSON.stringify(auth));
      return true;
    }
    return false;
  }

  public getAuthFromLocalStorage(): UserModel {
    try {
      const authData = JSON.parse(
        localStorage.getItem(this.authLocalStorageToken)
      );
      return authData;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  setMoreFunctionValue(value: boolean) {
    this.isMoreFunctionSubject.next(value);
  }

  setPrevScreenStateValue(value: 'mobile' | 'desktop') {
    this.prevScreenStateSubject.next(value);
  }

  impersonateUser(user: any) {
    debugger;
    var decoded: any = jwt_decode(user.accessToken);

    if (decoded?.role == 'Admin' || Array.isArray(decoded?.role)) {
      decoded?.role.find((f) => f === 'Admin')
        ? this.isAdminSubject.next(true)
        : this.isAdminSubject.next(false);
    } else {
      this.isAdminSubject.next(false);
    }

    // Set User data for Sellers
    user.id = decoded.nameid;
    user.verified = decoded.verified ? true : false;

    this.setAuthFromLocalStorage(user);
    this.currentUserSubject.next(user);
    return true;
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
