import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './modules/auth/_services/auth.guard';
import { DesktopDeviceGuard } from './pages/guards/desktop-device.guard';
import { MobileDeviceGuard } from './pages/guards/mobile-device.guard';
import { NgModule } from '@angular/core';

export const routes: Routes = [
  {
    path: '',
    canActivate: [DesktopDeviceGuard],
    children: [
      {
        path: '',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./pages/layout.module').then((m) => m.LayoutModule),
      },
    ],
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'error',
    loadChildren: () =>
      import('./modules/errors/errors.module').then((m) => m.ErrorsModule),
  },
  {
    path: 'm',
    canActivate: [MobileDeviceGuard, AuthGuard],
    loadChildren: () =>
      import('./mobile/mobile-layout.module').then((m) => m.MobileLayoutModule),
  },
  {
    path: 'p',
    loadChildren: () =>
      import('./pages/public/public.module').then((m) => m.PublicModule),
  },
  { path: '**', redirectTo: 'error/404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
